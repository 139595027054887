.Auth {
    border: 0.5px solid #399BE6;
}

.Auth_background {
    /*width: 100vw;*/
    /*height: 100vh;*/
    background: #F5F5F5;
}
.Auth_card {
    width: 405px;
    height: 346px;
    background: #FFFFFF;
    border-radius: 12px;
    border: none !important;
}

.Auth_card_body {
    padding-bottom: 0;
}

.Auth_title {
    margin-top: 25px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #585757;
}

.Auth_lable {
    margin-bottom: 4px !important;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #585757;
}

.Auth_control {
    height: 27px;
    margin-bottom: 12px;
    box-sizing: border-box;
    background: #EAEDFF !important;
    border: none !important;
    border-radius: 12px !important;
}

.Auth_control::placeholder {
    font-size: 12px;
}

.Auth_div_for_password_forgot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.Auth_forgot {
    margin-top: 5px;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #116CA3;
    text-decoration: none;
    text-align: right;
}

.Auth_div_for_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 25px;
}

.Auth_button {
    padding-left: 18px;
    padding-right: 18px;
    min-width: 126px;
    height: 27px;
    background: #7055D2;
    border-radius: 12px;
    border: none;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.Auth_bottom_note {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #989898;
}

.Auth_bottom_note_link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #116CA3;
    text-decoration: none;
}

.Auth_logout {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    margin-right: 42px;
    color: #000000;
}

.Auth_logout:hover {
    cursor: pointer;
}

