.container {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: flex-start;*/
}

.alert_auth {
    margin-top: 20px;
}

.groups_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    margin-bottom: 50px;
}

.h1 {
    margin-top: 35px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #585757;
}

.group {
    width: 350px !important;
    margin-bottom: 15px;
}

.group_buttons_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 20px !important;
    margin: 0;
    padding-left: 18px !important;
    padding-right: 10px !important;
}

.group_title_buttons {
    width: 350px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px !important;
    margin-bottom: 6px;
    border: 1px solid #7055D2;
    border-radius: 20px;
}

.group_title {
    width: 245px !important;
    /*width: 100%;*/
    min-height: 14px;
    /*height: 180px;*/
    padding-bottom: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #585757;
    border: none;
    background: #F5F5F5;
}

.group_button_wrapper {
    width: 20px !important;
    height: 20px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    padding-bottom: 10px !important;
    margin: 0;
}

.group_button_save{
    width: 20px;
    height: 20px;
    border: none;
    background: url('../../../public/img/save_group.svg')center no-repeat;
}

.group_button_del {
    width: 20px;
    height: 20px;
    border: none;
    background: url('../../../public/img/del_group.svg') center no-repeat;
}

.group_add_button_wrapper {
    width: 351px !important;
    /*display: flex !important;*/
    /*flex-direction: row !important;*/
    /*justify-content: flex-start !important;*/
    /*align-items: flex-start !important;*/
}

.group_add_button {
    width: 351px !important;
    height: 73px;
    margin-top: 10px !important;
    margin-bottom: 25px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px dashed #7055D2;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #585757;
    box-sizing: border-box;
}

.group_add_title {
    width: 350px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px !important;
    margin-bottom: 6px;
    padding-bottom: 4px;
    padding-left: 12px;
    border: 1px solid #7055D2;
    border-radius: 20px;
    /*width: 100%;*/
    min-height: 14px;
    /*height: 180px;*/
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #585757;
    background: #F5F5F5;
}

.task_text_buttons {
    width: 352px !important;
    /*min-height: 50px;*/
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}

.task_buttons_wrapper {
    width: 20px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4px;
}

.task_button_wrapper {
    width: 20px !important;
    height: 20px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;
    margin: 0;
}

.task_button_save{
    width: 20px;
    height: 20px;
    border: none;
    background: url('../../../public/img/save_task.svg')center no-repeat;
}

.task_button_del {
    width: 20px;
    height: 20px;
    border: none;
    background: url('../../../public/img/del_task.svg') center no-repeat;
}

.task_text_wrapper {
    padding: 1px !important;
}

.task_text {
    width: 325px !important;
    min-height: 80px;
    background: linear-gradient(90.01deg, rgba(198, 169, 255, 0) 0.01%, rgba(198, 169, 255, 0.3) 99.99%);
    padding-left: 15px;
    border-top: 4px solid #F5F5F5;
    border-right: 4px solid #F5F5F5;
    border-bottom: 4px solid #F5F5F5;
    border-left: 4px solid #C6A9FF;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #585757;
    /*line-height: 15px;*/
}

.task_text:hover {
    cursor: pointer;
    /*border-top: 4px solid #F5F5F5;*/
    /*border-right: 4px solid #F5F5F5;*/
    /*border-bottom: 4px solid #F5F5F5;*/
    border: 4px solid #C6A9FF;
}

/*.task_text:hover {*/
/*    cursor: pointer;*/
/*    width: 322px !important;*/
/*    !*box-sizing: border-box;*!*/
/*    background: red !important;*/
/*    border: 2px dashed #7055D2;*/
/*    border-radius: 4px;*/
/*    z-index: 100 !important;*/
/*}*/

.task_button_add {
    width: 126px;
    height: 27px;
    background: #7055D2;
    border-radius: 12px;
    border: none;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
}

.task_button_cancel {
    width: 106px;
    height: 27px;
    border: 1px solid #7055D2;
    border-radius: 12px;
    color: #7055D2;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
}

.task_add_wrapper {
    width: 350px !important;
    padding: 0 !important;
}

.task_add_text {
    width: 325px !important;
    min-height: 80px;
    background: linear-gradient(90.01deg, rgba(131, 196, 255, 0) 0.01%, rgba(131, 196, 255, 0.3) 99.99%);
    padding-left: 15px;
    border-top: 4px solid #F5F5F5 !important;
    border-right: 4px solid #F5F5F5 !important;
    border-bottom: 4px solid #F5F5F5 !important;
    border-left: 4px solid #83C4FF !important;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #585757;
}

.task_add_text::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #585757;
}

