.MenuNavBar_navbar {
    /*width: 100vw;*/
    min-height: 58px;
    background: #DEDBFE;
}

.MenuNavBar_link_logo {
    margin-right: 74px;
}

.MenuNavBar_link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    margin-right: 42px;
    color: #000000;
}